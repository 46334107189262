// Page Banner

.page-banner {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
        max-height: rem-calc(720);
    }
    .banner-image {
        width: 100%;
        img {
            width: 100%;
            @include breakpoint(1366px down) {
                height: 60vh;
                object-fit: cover;
            }

        }
    }
    .banner-text {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 0%;
        transform: translateY(-34%);


        .main-text {
            span {
                @include title_font;
                color: $white;
                @include banner_heading_font_size;
                display: inline-block;
                word-break: keep-all;
                text-shadow: rem-calc(1 1 4) $panel-color-mid-gray;
                @include breakpoint(medium) {
                  font-size: rem-calc(60);
                  line-height: 1;
                }
                @include breakpoint(large) {
                  font-size: rem-calc(90);
                  line-height: rem-calc(88);
                  margin-bottom: rem-calc(10);
                  max-width: 100%;
                  font-size: rem-calc(80);
                  line-height: 1.2;
                }
                @media screen and (min-width: 1024px) and (max-width: 1500px) {
                  font-size: rem-calc(70);
                  line-height: 1;
                  max-width: 100%;
                }
                @include breakpoint(small only) {
                  font-size: rem_calc(32);
                  line-height: rem_calc(38);
                  margin-bottom: rem-calc(10);
                }
            }
        }
        .additional-text {
          text-shadow: rem-calc(1 1 4) $panel-color-mid-gray;
            @include breakpoint(medium) {
                max-width: 80%;
            }
            @include breakpoint(large) {
                max-width: 70%;
            }
            @include breakpoint(xxlarge) {
                max-width: 60%;
            }
            span {
                @include body_font;
                color: $white;
                @include banner_subheading_font_size;
                @include breakpoint(large) {
                  font-size: rem-calc(30);
                  line-height: 1.2;
                }
                @media screen and (min-width: 1024px) and (max-width: 1500px) {
                  font-size: rem-calc(22);
                  line-height: 1.3;
                }
            }
        }
        .additional-text.grid-container,
        .main-text.grid-container {

            max-width: none;
            margin: 0;
        }
    }
    .banner-animation {
        position: absolute;
        width: 50%;
        height: 100%;
        @include breakpoint(medium) {
            max-width: 40%;
        }
        @include breakpoint(large) {
            top: rem-calc(100);
            width: auto;
            height: auto;
        }
        &.first-animation {
            top: 20%;
            right: 10%;
        }
        img {
            width: 60%;
            float: right;
        }
    }
}

.services {
    .page-banner {
        .banner-animation {
            .animation-image {
                img {
                    transform: translatey(0px);
                    animation: float 6s ease-in-out infinite;
                    @media (prefers-reduced-motion: reduce) {
                        .animation {
                          animation: none;
                        }
                    }
                }
            }
        }
    }
}

.post-type-archive-portfolio {
    .page-banner {
        .banner-animation {
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            max-width: 90%;
            .animation-image {
                width: 100%;
                position: relative;
                img {
                    position: absolute;
                    animation: paper-plane-soaring 4s ease-in 1s infinite forwards;
                    width: rem-calc(150);
                    height: auto;
                    @media (prefers-reduced-motion: reduce) {
                        .animation {
                          animation: none;
                        }
                    }
                    @include breakpoint(large) {
                        width: rem-calc(200);
                    }
                }
            }
        }
    }
}

.team {
    .page-banner {
        .banner-text {
            z-index: 10;
            .additional-text {
                @include breakpoint(xlarge) {
                    width: 70%;
                }
            }
        }
        .banner-animation {
            top: 0;
            right: 30%;
            z-index: 5;
            &.first-animation {
                .animation-image {
                    max-height: 100%;
                    overflow: hidden;
                    img {
                        animation: float 6s ease-in-out infinite;
                        width: auto;
                        height: auto;
                        max-height: 100vh;
                        @media (prefers-reduced-motion: reduce) {
                            .animation {
                              animation: none;
                            }
                        }
                        @include breakpoint(xxlarge) {
                            max-height: 90vh;
                        }
                    }
                }
            }
            &.second-animation {
                position: absolute;
                bottom: 0;
                right: 10%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                img {
                    animation: float_second 6s ease infinite;
                    @media (prefers-reduced-motion: reduce) {
                        .animation {
                          animation: none;
                        }
                    }
                }
            }
        }

    }
}
.page-banner.single-careers-banner .banner-text .main-text span {
    max-width: none;
}

@include breakpoint(medium) {
  .single-career .page-banner.single-careers-banner .banner-text {
    max-width: 75rem !important;
    .main-text span {
      font-size: rem-calc(80);
      line-height: 1;
    }

  }
}



.page-banner .banner-image img {
    max-height: 60vh;
    object-fit: cover;
}

// Header

header.header {
    padding: rem-calc(20);
    &.sticky-header {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: $color-colour-blue-50;
        z-index: 998;
        @include breakpoint(small only) {
          padding-right: 0.625rem;
          padding-left: 0.625rem;
        }
        .main-header {
            .logo {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include breakpoint(small only) {
                  padding-left: 0;
                }
                a {
                    // animation: slide-down-in ease-in 0.3s both;
                    @media (prefers-reduced-motion: reduce) {
                        .animation {
                          //animation: none;
                        }
                    }
                    svg {
                        width: rem-calc(120);
                        height: auto;
                        display: block;
                        transition: 0.5s ease-in-out all;
                      @include breakpoint(medium) {
                        width: rem-calc(200);
                        height: auto;
                      }

                    }
                }
            }
            .menu-icon-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .header-cta {
                    flex: 1 1 0px;
                    text-align: right;
                    margin-top: rem-calc(10);
                    .button-round {
                        margin-right: rem-calc(20);
                        margin-bottom: rem-calc(10);
                        @include button_round_small();
                        transition: all ease-in-out 0.3s;
                        @include button_hover;
                        @include breakpoint(medium) {
                            display: inline-block;
                        }
                        display: none;
                    }
                    .telephone {
                        margin-right: rem-calc(25);
                        color: $white;
                        @include breakpoint(medium) {
                            font-size: rem-calc(20);
                        }
                        font-size: rem-calc(18);
                        font-weight: 800;
                        .number {
                            @include breakpoint(medium) {
                                display: inline-block;
                            }
                            display: none;
                        }
                        .fa-phone-alt {
                            margin-right: rem-calc(10);
                            font-size: rem-calc(32);
                            @include breakpoint(medium) {
                                font-size: rem-calc(16);
                            }
                        }
                    }
                }
                .icon-wrapper {
                    background: $panel-color-orange;
                    width: rem-calc(60);
                    height: rem-calc(60);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .hamburger {
                        .line {
                            width: rem-calc(30);
                            height: 5px;
                            border-radius: rem-calc(5);
                            background-color: #ecf0f1;
                            display: block;
                            margin: 8px auto;
                            transition: all 0.3s ease-in-out;
                          }
                          &:hover {
                            cursor: pointer;
                          }
                    }
                }

                #hamburger-9 {
                    position: relative;
                    -webkit-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                  }

                  #hamburger-9.is-active {
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }

                  #hamburger-9:before {
                    content: "";
                    position: absolute;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 70px;
                    height: 70px;
                    border: 5px solid transparent;
                    top: calc(50% - 35px);
                    left: calc(50% - 35px);
                    border-radius: 100%;
                    -webkit-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                  }

                  #hamburger-9.is-active:before {
                    border: 5px solid #ecf0f1;
                  }

                  #hamburger-9.is-active .line {
                    width: 35px;
                  }

                  #hamburger-9.is-active .line:nth-child(2) {
                    opacity: 0;
                  }

                  #hamburger-9.is-active .line:nth-child(1) {
                    -webkit-transform: translateY(13px);
                    -ms-transform: translateY(13px);
                    -o-transform: translateY(13px);
                    transform: translateY(13px);
                  }

                  #hamburger-9.is-active .line:nth-child(3) {
                    -webkit-transform: translateY(-13px) rotate(90deg);
                    -ms-transform: translateY(-13px) rotate(90deg);
                    -o-transform: translateY(-13px) rotate(90deg);
                    transform: translateY(-13px) rotate(90deg);
                  }
                a.menu-icon {
                    background: $panel-color-orange;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: rem-calc(40);
                    height: rem-calc(40);
                    animation: fade-in 0.3s ease-in 0.3s 1 both;
                    @media (prefers-reduced-motion: reduce) {
                        .animation {
                          animation: none;
                        }
                    }
                    @include breakpoint(medium) {
                        width: rem-calc(60);
                        height: rem-calc(60);
                    }
                    .icon {
                        color: $white;
                        width: rem-calc(30);
                        height: rem-calc(30);
                        max-width: rem-calc(30);
                        max-height: rem-calc(30);
                        transition: all ease-in 0.3s;
                        @include breakpoint(medium) {
                            width: rem-calc(40);
                            height: rem-calc(40);
                            max-width: rem-calc(40);
                            max-height: rem-calc(40);
                        }
                    }
                }
            }
        }

    }
}

// Seperate/added styling for the homepage

@include breakpoint(small only) {

    .header {
      padding: 1.25rem !important;
      .main-header {
        .logo {
          padding-left: inherit !important;
        }
        .menu-icon-container {
          padding-right: rem-calc(0);
        }
      }
    }
    .home header.header.sticky-header .main-header .logo {
      padding-left: .625rem !important;
    }
  }

  @include breakpoint(large) {
    .home header.header.sticky-header .main-header .logo {
      padding-left: rem-calc(12) !important;
    }
  }

//  logo size is reduced for more screen real estate on the home page
.home header.header.sticky-header.scrolled .main-header .logo a svg,
.home header.header.sticky-header .main-header .logo a svg {
  @include breakpoint(small only) {
    width: rem-calc(140) !important;
  }
}

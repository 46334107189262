// Mixins

@mixin body_font {
    font-family: 'Muli', Tahoma, Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-display: swap;
}

@mixin heading_font {
    font-family: 'Muli', Tahoma, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-display: swap;
}

@mixin title_font {
    font-family: 'Muli', Tahoma, Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-display: swap;
}

@mixin button_round($background: #ffffff, $color: #000000) {
    padding: rem-calc(10 30);
    border-radius: rem-calc(20);
    border: none;
    background: $background;
    color: $color;
    cursor: pointer;
    @include breakpoint(medium) {
        padding: rem-calc(10 40);
    }
}


@mixin button_round_small($background: #F5A623, $color: #000000) {
    padding: rem-calc(10 30);
    border-radius: rem-calc(20);
    border: none;
    box-shadow: rem-calc(0 2 2) rgba($black, 0.6);
    background: $background;
    color: $color;
    cursor: pointer;
    text-align: center;
    font-size: rem-calc(14);
    @include breakpoint(medium) {
        padding: rem-calc(5 30);
    }
}


@mixin button_hover {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: rem-calc(0 2 2) rgba($black, 0.6);
    transition-duration: 0.5s;
    &:hover {
        transform: scale(1.2);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    }
}

@mixin banner_heading_font_size {
    font-size: rem-calc(28);
    @media screen and (min-width: rem-calc(370)) {
        font-size: rem-calc(32);
    }
    @include breakpoint(medium) {
        font-size: rem-calc(40);
    }
    @include breakpoint(large) {
        font-size: rem-calc(58);
    }
}
@mixin banner_subheading_font_size {
    font-size: rem-calc(14);
    @media screen and (min-width: rem-calc(370)) {
        font-size: rem-calc(16);
    }
    @include breakpoint(medium) {
        font-size: rem-calc(18);
    }
    @include breakpoint(large) {
        font-size: rem-calc(22);
    }
}

@mixin portfolio-tags-menu {
    justify-content: center;
    li {
        a,
        button {
            @include title_font;
            font-size: rem-calc(14);
            color: $body-font-color;
            background: $panel-color-light-gray;
            border-radius: rem-calc(10);
            margin: rem-calc(5);
            padding: rem-calc(11 16);
            cursor: pointer;
            transition: all ease-in 0.3s;
            @include breakpoint(medium) {
                font-size: rem-calc(16);
            }
            &:hover {
                background: darken($panel-color-light-gray, 10);
            }
        }
        &.active {
            a {
                background: $panel-color-dark-gray;
                color: $white;
            }
        }
    }
}

@mixin footer-contact-form {
    label {
        color: $secondary-colour-gold;
        span {
            color: red;
        }
        input,
        textarea {
            width: 100%;
            border: rem-calc(1) solid $form-border-colour;
            padding: rem-calc(5);
        }

    }
    .consent {
        padding: rem-calc(25 0);
        ul li {
            width: 100%;
            overflow: hidden;
            margin: 0;
            span {
                &.privacy-consent,
                &.email-consent {
                    float: left;
                    margin-right: rem-calc(20);
                    input[type=checkbox] {
                        margin-right: rem-calc(10);
                        width: rem-calc(15);
                        height: rem-calc(15);
                    }
                }
            }
            span.wpcf7-list-item {
                width: 100%;
                text-align: left;
            }
            span.wpcf7-list-item-label {
                color: $body-font-color;
                a {
                    color: $secondary-colour-gold;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .submit {
        text-align: center;
        input.wpcf7-submit {
            //@include button_round;
            //@include button_hover;
            background: $secondary-colour-gold;
            color: $white;
            padding: rem-calc(10 60);
            width: rem-calc(200);
            @include standard_button_round_new;
            background: $new-button-gradient-orange;
        }
    }
    p:empty { display: none; }
}



@mixin quote-contact-form {
    label {
        //color: $secondary-colour-gold;
        span {
            color: red;
        }
        input,
        textarea {
            width: 100%;
            border: rem-calc(1) solid $form-border-colour;
            padding: rem-calc(5);
            margin-bottom: rem-calc(15);
        }
        input[type="checkbox"] {
            width: auto;
        }
    }
    .consent {
        padding: rem-calc(5 0);
        ul li {
            width: 100%;
            overflow: hidden;
            margin: 0;
            span {
                &.privacy-consent,
                &.email-consent {
                    float: left;
                    margin-right: rem-calc(20);
                    input[type=checkbox] {
                        margin-right: rem-calc(10);
                        width: rem-calc(15);
                        height: rem-calc(15);
                    }
                }
            }
            span.wpcf7-list-item {
                width: 100%;
                text-align: left;
            }
            span.wpcf7-list-item-label {
                color: $body-font-color;
                a {
                    color: $secondary-colour-gold;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .submit {
        text-align: center;
        margin-bottom: rem-calc(25);
        input.wpcf7-submit {
            @include button_round;
            @include button_hover;
            background: $secondary-colour-gold;
            color: $black;


        }
    }
    p:empty { display: none; }
}



@mixin social-icons($color: #ffffff, $border: rem-calc(1)) {
    align-items: center;
    justify-content: center;
    li {
        transition: all ease-in 0.3s;
        @include breakpoint(large) {
            &:hover {
                a .icon {
                    transform: rotate(45deg);
                    color: $primary-colour-blue;
                }
            }
        }
        a {
            color: $color;
            padding: 0;
            margin: rem-calc(0 5);
            width: rem-calc(45);
            height: rem-calc(45);
            border: $border solid $color;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include breakpoint(medium) {
                width: rem-calc(55);
                height: rem-calc(55);
            }
            .icon {
                width: rem-calc(22);
                height: rem-calc(23);
                font-size: rem-calc(23);
                transition: transform 0.3s ease-in-out;
                @include breakpoint(medium) {
                    width: rem-calc(26);
                    height: rem-calc(28);
                    font-size: rem-calc(28);
                }
            }
        }
    }
}

@mixin sidebar-social {
    position: absolute;
    top: 20%;
    right: 0;
    ul.menu {
        @include social-icons($secondary-colour-gold, rem-calc(3));
        li {
            margin-bottom: rem-calc(10);
        }
    }
}

@mixin inner-page-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: rem-calc(200);
    @include breakpoint(medium) {
        height: rem-calc(360);
        max-height: rem-calc(360);
    }
    @include breakpoint(large) {
        height: rem-calc(400);
        max-height: rem-calc(400);
    }
    .banner-bg {
        width: 100%;
        height: 100%;
        position: relative;
        background-image: url('./../images/backgrounds/lazy-load-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }
    .banner-text {
        width: 100%;
        padding: rem-calc(20);
        @include breakpoint(medium) {
            max-width: 80%;
        }
        @include breakpoint(large) {
            padding: rem-calc(20 40 50);
            max-width: 70%;
        }
        @include breakpoint(xxlarge) {
            max-width: 60%;
        }
    }
}


@mixin button_round_new {
    padding: rem-calc(16, 42);
    @include button_round();
    transition: all  ease-in-out 0.3s;
    color: #fff;
    text-shadow: rem-calc(1 1 4) $panel-color-mid-gray;
    font-weight: 600;
    transition: background 0.3s ease;
    transform: translateY(0em);
    transition: .8s;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: rem-calc(1 1 4) #00000080;
    background: $primary-colour-orange;
    background: $background-gradient-orange;
    //@include button_hover;
    @include breakpoint(medium) {
        padding: rem-calc(16 42);
        border-radius: rem-calc(30);
    }
    @include breakpoint(small only) {
        padding: rem-calc(12 35);
    }
}

@mixin button_round_hover_new {
    &:hover {
        text-shadow: rem-calc(1 1 4) #000000;
        transform: translateY(-10px);
        box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
        box-shadow: 0 0 4px 0px #ffffff, 0 0 10px #a8d4f7, 0 0 30px #ffedc3, 0px 10px 19px 6px #000000;
        border: 1px solid white;
    }
}

//Use this for buttons on a white background
@mixin standard_button_round_new {
    @include button_round_new();
    position: relative;
    min-width: rem-calc(220);
    display: inline-block;
    font-size: rem-calc(18);
    border-color: #fff;
    transition: .3s;
    opacity: 1;
    background: linear-gradient(0deg, rgba(42,48,96,1) 50%, rgba(101,105,140,1) 100%);
    background: linear-gradient(0deg, rgba(252,156,8,1) 50%, rgba(255,222,143,1) 100%);
    border-radius: rem-calc(140);
    &:hover {
        text-shadow: rem-calc(1 1 4) #000000d1;
        transform: translateY(rem-calc(-5));
        opacity: 0.9;
    }
}

@mixin page_contact_form {
    label {
        color: $new-border-grey-1;
        font-size: rem-calc(16);
        margin-bottom: rem-calc(28);
        display: inline-block;
        width: 100%;

        input,
        textarea {
            width: 100%;
            border: 1px solid $form-border-colour;
            @include body_font;
            padding: rem-calc(14 10);
            margin-top: rem-calc(12);
        }
        input {
            font-size: rem-calc(15);
            padding: rem-calc(14 10);
            margin-top: rem-calc(12);
        }
    }
    .consent {
        label {
            @include body_font;
            margin-bottom: rem-calc(16);
            position: relative;
            input {
                margin-top: 0 !important;
                position: absolute;
                top: 3px;
            }
        }
    }
    .consent {
        ul {
            list-style-type: none;
            margin-left: 0;

            li {
                .wpcf7-list-item {
                    display: inline-block;
                    margin: 0 0 0 0;

                    label {
                        @include body_font;
                        color: $body-font-color;
                        font-size: rem-calc(13);

                        a {
                            color: $body-font-color;
                            font-size: rem-calc(13);
                        }

                        input {
                            width: auto;
                            display: inline;
                        }

                        span.wpcf7-list-item-label {
                            margin-left: rem-calc(25);
                            position: relative;
                            display: inline-block;
                            color: $new-border-grey-3;
                        }
                    }
                }
            }
        }
    }
    .submit {
        text-align: center;
        input.wpcf7-form-control.wpcf7-submit {
            @include standard_button_round_new;
            background: $new-button-gradient-orange;
            background: $new-button-gradient-orange;
            margin-top: rem-calc(12);
        }
    }
    .wpcf7 .wpcf7-submit:disabled {
        opacity: 0.7
    }
}

@mixin front_page_title_res_lapt {
    max-width: rem-calc(460) !important;
    font-size: rem-calc(50) !important;
    line-height: 1.2 !important;
    margin-bottom: rem-calc(10) !important;
}
@mixin front_page_content_res_lapt {
    margin-top: rem-calc(10) !important;
}

@mixin front_page_text_res_lapt {
    font-size: rem-calc(16) !important;
}

// Page Loader

.loaded {
    .spinner-wrapper {
        visibility: hidden;
    }
}

.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #211747;
    z-index: 999999;
    visibility: visible;
    transition: visibility 1s ease;
    .moon {
        width: 200px;
        height: 200px;
        svg path {
            animation: moon-fill 2s ease 0s infinite;
        }
    }
}

@keyframes moon-fill {
    0% {
        opacity: 0;
    }
    100% {
        fill: #ffffff;
        opacity: 1;
    }
}
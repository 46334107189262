// Text Page Banner - Critical

.text-page-banner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: rem-calc(200);
    max-height: rem-calc(200);
    @include breakpoint(medium) {
        min-height: rem-calc(400);
        max-height: rem-calc(400);
    }
    .image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .banner-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .banner-text {
            height: 100%;
            max-width: $global-width;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .text-wrapper {
                padding: rem-calc(10);
                @include breakpoint(medium) {
                    padding: rem-calc(20);
                }
                span.title {
                    @include title_font;
                    color: $white;
                    font-size: rem-calc(26);
                    @include breakpoint(medium) {
                        font-size: rem-calc(45);
                    }
                }
                p {
                    @include body_font;
                    color: $white;
                    font-size: rem-calc(16);
                    @include breakpoint(medium) {
                        font-size: rem-calc(20);
                    }
                }
            }

        }
    }
}
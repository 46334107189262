// Portfolio Banner - Critical

.portfolio-banner {
    width: 100%;
    position: relative;
    padding: rem-calc(120 0 20);

    @include breakpoint(small down) {
        padding-bottom: rem-calc(30);
    }
    .banner-inner {
        width: 100%;
        position: relative;

        @include breakpoint(large down) {
            display: flex;
            flex-direction: column;
        }
        @include breakpoint(small down) {
            padding-bottom: rem-calc(210);
        }

        .banner-image-portfolio {
            position: absolute;
            left: 50%;
            bottom: rem-calc(0);
            transform: translateX(-50%);
            @include breakpoint(medium) {
                position: absolute;
                left: unset;
                bottom: 1.25rem;
                right: 0;
                transform: translateY(-50%);
                top: 50%;
                height: max-content;
            }

            @include breakpoint(medium) {
                right: rem-calc(35);
                transform: translateY(-50%);
                top: 50%;
            }

            img.banner-image-portfolio-img {
                max-height: rem-calc(210);
                max-width: none;
                @include breakpoint(medium) {
                    max-height: rem-calc(250);
                    max-width: rem-calc(200);
                    object-fit: cover;
                }
                @include breakpoint(large) {
                    max-width: none;
                }
            }

        }

        .banner-title {
            z-index: 20;
            padding: rem-calc(0 20);
            position: relative;
            margin-top: rem-calc(20);

            @include breakpoint(large down) {
                order: 1;
            }
            span.title {
                @include title_font;
                color: $white;
                text-shadow: rem-calc(1 1 5) $black;
                font-size: rem-calc(24);
                word-break: keep-all;
                @include breakpoint(medium) {
                    font-size: rem-calc(32);
                }
                @include breakpoint(large) {
                    font-size: rem-calc(44);
                }
            }
        }
        .banner-buttons {
            padding: rem-calc(0 20);
            position: relative;
            z-index: 20;
            overflow: auto;

            @include breakpoint(large) {
                max-width: 70%;
            }
            @include breakpoint(large down) {
                order: 3;
            }
            @include breakpoint(medium down) {
                max-width: 66%;
            }
            .menu {
                @include breakpoint(small only) {
                    width: 170%;
                }
                @include portfolio-tags-menu;
                justify-content: flex-start;

                li {
                    a {
                        margin-left: 0;
                        @include breakpoint(large down) {
                            font-size: rem-calc(14);
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .banner-text {
            width: 100%;
            height: 100%;
            padding: rem-calc(20);
            z-index: 10;
            @include breakpoint(medium) {
                width: 70%;
            }
            @include breakpoint(large down) {
                order: 2;
                width: 66%;
            }
            @include breakpoint(small down) {
                width: 100%;
            }
            p {
                @include body_font;
                color: $white;

                &:last-of-type {
                    @include breakpoint(large down) {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .banner-image {
            width: 50%;
            height: 100%;
            position: absolute;
            top: rem-calc(50);
            right: 0;
            text-align: right;
            z-index: 10;
            @include breakpoint(medium) {
                position: relative;
            }
            img {
                width: auto;
                height: auto;
                max-height: rem-calc(400);
            }
        }
    }
}
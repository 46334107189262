// Fonts

body {
    @include body_font;

    h1,
    h2,
    h3,
    h4,
    h5 {
        @include heading_font;
    }
    p {
        @include body_font;
        font-size: rem-calc(16);
        a,
        span {
            @include body_font;
        }
    }
    ul,
    ul li,
    ul li a {
        @include body_font;
    }
}

// Buttons

.page-section-button {
    margin-top: rem-calc(20);
    .button-round {       
        margin-bottom: rem-calc(10);
        @include breakpoint(small only) {
            font-size: rem-calc(12);
        }
        @include button_round();
        transition: all ease-in-out 0.3s;
        @include button_hover;
        @include breakpoint(medium) {
            padding: rem-calc(16 42);
            border-radius: rem-calc(30);
            margin-right: rem-calc(20);
        }
        padding: rem-calc(5 10);
        margin-right: rem-calc(10);
        &:hover {
            background: $secondary-colour-gold;
            color: $white;
        }
    }
    .gold {
        background: $secondary-colour-gold !important;
    }

}
    





// Colours

$primary-colour-blue: #2A3060;
$primary-colour-orange: #EA9E30;
$primary-colour-darker-blue: #151635;
$primary-colour-mid-blue: #262A57;
$primary-colour-lighter-blue: #6878ED;
$secondary-colour: #292D5A;
$secondary-colour-gold: #F5A623;
$menu-icon-colour: #e6e6e6;
$offcanvas-background: $secondary-colour;
$body-font-color: #4A4A4A;
$panel-color-offwhite: #f1f1f1;
$panel-color-orange: #F5A623;
$panel-color-dark-gray: #626262;
$panel-color-mid-gray: #1e1e1edb;
$panel-color-light-gray: #ECECEC;
$panel-color-about: #DBDBDB;
$panel-color-careers: #F8F8F8;
$form-border-colour: #BDBDBD;
$blog-panel-border: #9B9B9B;
$blog-sidebar1: #2A3060;
$blog-sidebar2: #373E78;
$blog-sidebar3: #454D8B;
$light-grey: #979797;
$menu-light-grey: #b5b5b5;
$new-button-gradient-orange: linear-gradient(0deg, rgba(252,156,8,1) 50%, rgba(255,222,143,1) 100%);
$color-colour-blue-50:  unquote('#2A306080');
$new-border-grey-1: #8f8f8f;
$new-border-grey-2: #e7e7e7;
$new-border-grey-3: #4A4A4A;
$background-gradient-orange: linear-gradient(0deg, rgba(252,156,8,1) 50%, rgba(255,222,143,1) 100%);
$background-gradient-blue: linear-gradient(0deg, rgba(42,48,96,1) 50%, rgba(101,105,140,1) 100%);
